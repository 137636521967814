import angular from './images/angular-48.png';
import python from './images/python-48.png';
import javascript from './images/javascript-48.png';
import react from './images/react-48.png';
import node from './images/nodejs-48.png';
import api from './images/api-48.png';
import aws from './images/aws-48.png';
import linkedin from './images/linkedin-48.png';
import { useEffect, useState } from 'react';
import 'animate.css';


function App() {
  const generate_col_with_images = () =>{
    const images = [angular, python, javascript, react, node, api, aws];

    return <div className='row'>
      {images.map((image, index) => {
        return <div id='brand' className='col' key={index}>
          <img src={image} alt='logo'/>
          </div>
      })}
      </div>
  }
  const [x, setX] = useState()
  const [y, setY] = useState()
  useEffect(
    () => {
      const update = (e) => {
        setX(e.x)
        setY(e.y)
      }
      window.addEventListener('mousemove', update)
      window.addEventListener('touchmove', update)
      
      return () => {
        window.removeEventListener('mousemove', update)
        window.removeEventListener('touchmove', update)
        console.log(x, y);
      }
      
    },
    [setX, setY]
  )



  return (
    <>
    <div className="container-fluid" >
      <div className="vh-100 row justify-content-center align-items-center">
        <div className="col-lg-6 col-sm-8 text-center">
          <div className="card animate__animated animate__bounce">
            <div className="card-body">
              <h2 className="card-title">Bienvenido !!</h2>
              <p className="card-text">Hola, soy Maximiliano Ibáñez 👋🏻👋🏻</p>
              <p className="card-text">Desarrollador💻 amante del café ☕</p>
              <p className="card-text">Soy un desarrollador fullstack en crecimiento.</p>
              <p className="card-text">Con conocimientos backend en servicios de AWS, API REST y NodeJS</p>
              <p className="card-text">y con conocimientos en frontend como HTML, CSS, Bootstrap.</p>
              <p className="card-text">He trabajado y sigo aprendiendo de frameworks como Angular, React.js, Serverless, Bootstrap y otros.</p>
              <br/>
              {generate_col_with_images() }
              <br/>
              <p className="card-text">Visita mi perfil en linkedIn si quieres saber un poco más de mí.</p>
              <p className='card-text'><a href="https://www.linkedin.com/in/maximilianoibanez/" target="_blank" rel="linkedIn.com"><img src={linkedin} /></a></p>
            </div>
          </div>
        </div>

      </div>  
    </div>
  
    </>
  );
}

export default App;

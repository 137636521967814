import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
const listBackgroundColors = [
  '#BEE9E8',
  '#CAE9FF',
  '#DADFF7',
  '#FFF9A5',
  '#F1F2F6',
  '#FFEDDF',
  '#EDE7D9',
  '#C0FDFB',
  '#CCD5FF',
  '#9CC4B2'
]
const background = ()=>{
  return 'background-color:'+listBackgroundColors[Math.floor(Math.random() * listBackgroundColors.length)];
}
document.getElementById('root').style = background();
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
